// @flow
import * as React from 'react'
import styled, { withTheme } from 'styled-components'
import { rem } from 'polished'

import { durations } from '../../styles/animations'

import { mediaQuery, mediaOnly } from '../../styles/media'
import { color } from '../../styles/theme'
import { getFontSize } from '../../styles/style-helpers'
import Ribbon from '../../components/Ribbon/Ribbon'
import Image from '../../components/Image/Image'
import Heading from '../../components/Heading/Heading'
import Markdown from '../../components/Markdown/Markdown'
import Button from '../../components/Button/Button'
import MediaQuery from '../../components/MediaQuery/MediaQuery'
import Link from '../../components/Link'
import type { EntryLinkViewModel } from '../../types/EntryLinkViewModel'
import { HyphenatedText } from '../../components/HyphenatedText/HyphenatedText'

export type Props = EntryLinkViewModel

type State = {}

const EntryLinkDeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => rem(theme.borderRadius)};
  ${mediaQuery.md`
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  `}
`

const EntryLinkDesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => rem(theme.borderRadius)};
  ${mediaQuery.md`
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  `}

  &:hover img {
    transform: scale(1.1);
  }
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  min-height: ${rem(233)};
  border-top-right-radius: ${({ theme }) => rem(theme.borderRadius)};
  border-top-left-radius: ${({ theme }) => rem(theme.borderRadius)};
  ${mediaQuery.md`
    flex-basis: 50%;
    flex-shrink: 0;
    height: ${rem(584)};
    padding-bottom: 0;
    object-fit: contain;
    z-index: 1;
    ${({ reverse, theme }) =>
      reverse
        ? `
          border-top-right-radius: ${rem(theme.borderRadius)};
          border-bottom-right-radius: ${rem(theme.borderRadius)};
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `
        : `
          border-top-left-radius: ${rem(theme.borderRadius)};
          border-bottom-left-radius: ${rem(theme.borderRadius)};
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        `}
  `};
`

const ContactTextWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.white};
  background-color: ${({ image, contactBackgroundColor, theme }) =>
    image ? 'transparent' : contactBackgroundColor || theme.primary};
  ${({ image }) => (image ? `text-shadow: 2px 2px rgba(0, 0, 0, 0.2);` : null)}
`

const ContactTextWrapperInner = styled.div`
  width: 100%;
  text-align: center;
`

const ContactTitle = styled.div`
  font-size: ${rem(22)};
  font-weight: 900;
  margin-bottom: ${rem(24)};
  color: ${({ contactTitleColor }) => contactTitleColor || color.white};
`

const ContactText = styled.div`
  font-size: ${rem(42)};
  font-weight: 900;
  color: ${({ contactTextColor }) => contactTextColor || color.accent};
  transition: transform ${durations.medium} ease-in-out;

  :hover {
    transform: scale(1.05);
  }

  ${mediaQuery.sm`
    font-size: ${rem(56)};
  `}

  ${mediaQuery.md`
    font-size: ${rem(64)};
  `}
`

const ContentWrapper = styled.div`
  padding: ${rem(32)} ${rem(32)} ${rem(48)};
  border-bottom-right-radius: ${({ theme }) => rem(theme.borderRadius)};
  border-bottom-left-radius: ${({ theme }) => rem(theme.borderRadius)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : color.white};

  ${mediaQuery.md`
    display: flex;
    align-items: center;
    padding: 0 ${rem(64)};
    flex-basis: 50%;
    height: ${rem(584)};
    ${({ reverse, theme }) =>
      reverse
        ? `
        border-top-left-radius: ${rem(theme.borderRadius)};
        border-bottom-left-radius: ${rem(theme.borderRadius)};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `
        : `
        border-top-right-radius: ${rem(theme.borderRadius)};
        border-bottom-right-radius: ${rem(theme.borderRadius)};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `}
  `}
`

const ContentSection = styled(Heading)`
  && {
    display: block;
    margin-bottom: ${rem(8)};
    ${mediaQuery.md`
      margin-bottom: ${rem(16)};
    `}
    ${({ backgroundColor }) =>
      backgroundColor === '#ffffff' || !backgroundColor
        ? `color: ${color.accent};`
        : `color: ${color.white};`}
  }

  letter-spacing: 1px;
  text-transform: uppercase;
`

const ContentHeading = styled(Heading)`
  && {
    ${({ backgroundColor, textColor, theme }) =>
      backgroundColor === '#ffffff' || !backgroundColor
        ? `color: ${textColor || theme.primary};`
        : `color: ${textColor || theme.textInvert};`}
  }
`

const ContentText = styled(Markdown)`
  && {
    ${({ backgroundColor, textColor, theme }) =>
      backgroundColor === '#ffffff' || !backgroundColor
        ? `color: ${textColor || theme.text};`
        : `color: ${textColor || theme.textInvert};`}
  }

  ${mediaQuery.md`
    font-size: ${getFontSize('large')};
  `}
`

const ContentButton = styled(Button)`
  margin-top: ${rem(48)};
  ${mediaOnly.sm`
    display: none;
  `}
  ${mediaOnly.xs`
    display: none;
  `}
`

class EntryLink extends React.Component<Props, State> {
  static displayName = 'EntryLink'
  static defaultProps = {}

  createInnerContent = (props: Props) => {
    const { backgroundColor, theme } = props
    const invertLinkColor =
      backgroundColor === '#ffffff' ||
      !backgroundColor ||
      (theme && backgroundColor === theme.cardNegativeBackground)

    return (
      <React.Fragment>
        <ImageWrapper {...props}>
          {props.image && (
            <Image {...props.image} cover fillContainer zoomOnHover />
          )}
          {((props.contactLink && props.contactLink.label) ||
            props.contactTitle ||
            props.contactText) && (
            <ContactTextWrapper {...props}>
              <ContactTextWrapperInner>
                {props.contactTitle && (
                  <ContactTitle {...props}>{props.contactTitle}</ContactTitle>
                )}
                {props.contactLink && (
                  <Link invert={invertLinkColor} {...props.contactLink}>
                    <ContactText {...props}>
                      {props.contactLink.label}
                    </ContactText>
                  </Link>
                )}
                {props.contactText && (
                  <ContactText {...props}>{props.contactText}</ContactText>
                )}
              </ContactTextWrapperInner>
            </ContactTextWrapper>
          )}
        </ImageWrapper>
        <ContentWrapper {...props}>
          <div>
            {props.topic && (
              <ContentSection
                responsiveSize
                themed
                tag="span"
                level={5}
                backgroundColor={props.backgroundColor}
                textColor={props.textColor}
              >
                <HyphenatedText text={props.topic} minWordLength={4} />
              </ContentSection>
            )}
            {props.title && (
              <ContentHeading
                themed
                responsiveSize
                tag="h2"
                level={2}
                backgroundColor={props.backgroundColor}
                textColor={props.textColor}
              >
                <HyphenatedText text={props.title} minWordLength={4} />
              </ContentHeading>
            )}
            {props.text && (
              <ContentText
                invert={invertLinkColor}
                source={props.text}
                noMargin
                backgroundColor={props.backgroundColor}
                textColor={props.textColor}
              />
            )}
            {props.link && props.link.href && props.link.label && (
              <ContentButton
                {...props.link}
                negative={props.buttonNegative}
                type={
                  props.theme &&
                  (props.theme.name === 'kids' || props.theme.name === 'lgbt')
                    ? 'primary'
                    : 'normal'
                }
              >
                {props.link && props.link.label}
              </ContentButton>
            )}
          </div>
        </ContentWrapper>
      </React.Fragment>
    )
  }

  render() {
    const { reverse, firstBlock } = this.props

    const InnerContent = this.createInnerContent(this.props)

    return (
      <Ribbon paddingTop={firstBlock ? 50 : undefined}>
        <MediaQuery query={`(max-width: 1024px)`} defaultMatches={false}>
          <EntryLinkDeviceWrapper reverse={reverse}>
            {InnerContent}
          </EntryLinkDeviceWrapper>
        </MediaQuery>
        <MediaQuery query={`(min-width: 1025px)`} defaultMatches>
          <EntryLinkDesktopWrapper reverse={reverse}>
            {InnerContent}
          </EntryLinkDesktopWrapper>
        </MediaQuery>
      </Ribbon>
    )
  }
}

export default withTheme(EntryLink)
